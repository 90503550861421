<template>
  <div>
    <a-modal
      v-model="modeShow"
      title="Update Variants"
      ok-text="Next"
      @ok="handleOk"
      @cancel="maskClose"
    >
      <div class="title">
        please select your variants again to update your product
      </div>
      <div
        v-for="(item, index1) in specifications"
        :key="index1"
        class="specification-item"
      >
        <div class="item-title">{{ item.name }}</div>
        <div class="item-wrapper">
          <label
            v-for="(item_value, index2) in item.items"
            :key="index2"
          ><input
             type="checkbox"
             :checked="item_value.ischeck"
             @change.stop="skuClick(item_value, index1, $event, index2)"
           >
            <span
              v-if="item_value.option.color"
              :style="'background-color:' + item_value.option.color"
            />{{ item_value.name }}
          </label>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { productDetail } from '@/api/product'
export default {
  props: {
    productId: {
      type: [String, Number]
    },
    cusId: {
      type: Number
    }
  },
  data() {
    return {
      specifications: [],
      skutipArr: [],
      selectSkuIdList: [],
      skuInfos: [],
      modeShow: true
    }
  },
  mounted() {
    this.getSku()
  },
  methods: {
    async getSku() {
      const { data, code } = await productDetail({
        id: this.productId,
        skuInfos: true,
        skuMap: true
      })
      if (code === 200) {
        const skumap = data.skuMaps
        for (let i = 0; i < skumap.length; i++) {
          for (let j = 0; j < skumap[i].items.length; j++) {
            if (skumap[i].items[j].option) {
              skumap[i]['items'][j]['ischeck'] = true
              skumap[i].items[j].option = JSON.parse(skumap[i].items[j].option)
            }
          }
        }
        data.skuInfos.forEach(item => {
          if (item.option1) {
            item.option1 = JSON.parse(item.option1)
          }
          if (item.option2) {
            item.option2 = JSON.parse(item.option2)
          }
          if (item.option3) {
            item.option3 = JSON.parse(item.option3)
          }
        })
        this.skuInfos = data.skuInfos
        this.specifications = skumap
        console.log('sku...', this.specifications)
      }
    },
    skuClick(value, index1, event, index2) {
      if (this.specifications[index1]['items'][index2]['ischeck']) {
        this.specifications[index1]['items'][index2]['ischeck'] = false
        this.$forceUpdate()
      } else {
        this.specifications[index1]['items'][index2]['ischeck'] = true
        this.$forceUpdate()
      }
    },
    // 获取选中的sku
    getSelectSkuIdList() {
      const len = this.specifications.length
      this.selectSkuIdList.length = 0
      this.skutipArr = ''
      const param = {
        sku0: 0,
        sku1: 0,
        sku2: 0
      }
      for (let i = 0; i < this.specifications.length; i++) {
        for (let j = 0; j < this.specifications[i]['items'].length; j++) {
          if (this.specifications[i]['items'][j]['ischeck']) {
            param['sku' + i] = param['sku' + i] + 1
            this.selectSkuIdList.push(
              ...this.specifications[i]['items'][j]['ids']
            )
          }
        }
      }
      const a = {}
      var repArr = []
      for (let t = 0; t <= this.selectSkuIdList.length - 1; t++) {
        const v = this.selectSkuIdList[t]

        if (a[v]) {
          a[v]++
        } else {
          a[v] = 1
        }
      }
      for (const key in a) {
        if (a[key] === len) {
          repArr.push(parseInt(key))
        }
      }
      if (this.specifications.length === 1) {
        if (param.sku0 === 0) {
          this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
        }
      } else if (this.specifications.length > 1) {
        this.selectSkuIdList = repArr
        if (this.specifications.length === 2) {
          if (param.sku0 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
          }
          if (param.sku1 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[1].name + ' '
          }
          // 有两种属性但是只选了一种
        } else if (this.specifications.length === 3) {
          if (param.sku0 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[0].name + ' '
          }
          if (param.sku1 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[1].name + ' '
          }
          if (param.sku2 === 0) {
            this.skutipArr = this.skutipArr + this.specifications[2].name + ' '
          }
        }
      }
    },
    handleOk() {
      this.getSelectSkuIdList()
      if (this.skutipArr !== '') {
        this.$message.warn('Choose at least one [' + this.skutipArr + ']')
        return
      }
      this.$emit('open')
      this.setPrice()
      console.log('sss...', this.selectSkuIdList)
    },
    setPrice() {
      console.log('skuInfos...', this.skuInfos)
      const sku1 = []
      this.selectSkuIdList.forEach(choiceItem => {
        this.skuInfos.forEach(skuItem => {
          if (choiceItem === skuItem.id) {
            if (skuItem.option1.text) {
              const param = {}
              param.compareAtPrice = skuItem.recommendCompareAtPrice
              param.costs = skuItem.price
              param.isUp = true
              param.name = skuItem.option1.text
              if (!skuItem.option2 || !skuItem.option2.text) {
                param.id = skuItem.id
              }
              param.profit = skuItem.recommendRetailPrice - skuItem.price
              param.profit2 =
                (skuItem.recommendRetailPrice - skuItem.price) /
                skuItem.recommendRetailPrice
              param.retailPrice = skuItem.recommendRetailPrice
              param.sku2 = []
              sku1.push(param)
              if (skuItem.option2 && skuItem.option2.text) {
                let i = 0
                sku1.forEach(item => {
                  if (item.name === skuItem.option1.text) {
                    if (item.name === skuItem.option2.text) {
                      i = i + 1
                      if (i > 0) {
                        console.log(item.name)
                      }
                    }
                    const param = {}
                    param.compareAtPrice = skuItem.recommendCompareAtPrice
                    param.costs = skuItem.price
                    param.name = skuItem.option2.text
                    param.name1 = skuItem.option1.text
                    param.profit = skuItem.recommendRetailPrice - skuItem.price
                    if (!skuItem.option3 || !skuItem.option3.text) {
                      param.id = skuItem.id
                    }
                    if (skuItem.option3 && skuItem.option3.text) {
                      param.isUp = false
                    }
                    param.profit2 =
                      (skuItem.recommendRetailPrice - skuItem.price) /
                      skuItem.recommendRetailPrice
                    param.retailPrice = skuItem.recommendRetailPrice
                    param.skuthree = []
                    let num = 0
                    // 判断是否重复过
                    item.sku2.forEach(sku2item => {
                      if (sku2item.name === skuItem.option2.text) {
                        num = num + 1
                        if (skuItem.option3 && skuItem.option3.text) {
                          const param4 = {}
                          param4.compareAtPrice =
                            skuItem.recommendCompareAtPrice
                          param4.costs = skuItem.price
                          param4.name = skuItem.option3.text
                          param4.id = skuItem.id
                          param4.profit =
                            skuItem.recommendRetailPrice - skuItem.price
                          param4.profit2 =
                            (skuItem.recommendRetailPrice - skuItem.price) /
                            skuItem.recommendRetailPrice
                          param4.retailPrice = skuItem.recommendRetailPrice
                          sku2item.skuthree.push(param4)
                        }
                      }
                    })
                    if (num === 0) {
                      item.sku2.push(param)
                    }
                    if (skuItem.option3 && skuItem.option3.text) {
                      const param3 = {}
                      param3.compareAtPrice = skuItem.recommendCompareAtPrice
                      param3.costs = skuItem.price
                      param3.name = skuItem.option3.text
                      param3.id = skuItem.id
                      param3.profit =
                        skuItem.recommendRetailPrice - skuItem.price
                      param3.profit2 =
                        (skuItem.recommendRetailPrice - skuItem.price) /
                        skuItem.recommendRetailPrice
                      param3.retailPrice = skuItem.recommendRetailPrice
                      param.skuthree.push(param3)
                    }
                  }
                })
              }
            }
          }
        })
      })
      // 数组去重
      const deWeightThree = () => {
        const map = new Map()
        for (const item of sku1) {
          if (!map.has(item.name)) {
            map.set(item.name, item)
          }
        }
        return [...map.values()]
      }
      this.variant = deWeightThree()
      console.log('var...', this.variant)
      this.$emit('setSkuPrice', this.variant)
    },
    maskClose() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="scss">
.specification-item {
  margin-left: 20px;
  .item-wrapper label {
    margin-right: 0px !important;
    width: 33%;
  }
  .item-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 21px;
    margin-top: 22px;
  }
  .item-wrapper {
    display: flex;
    flex-wrap: wrap;
    label {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 36px;
      margin-bottom: 16px;
    }
    span {
      display: inline-block;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: red;
      margin-right: 8px;
      border: 1px solid #e6e6e6;
    }
    input[type="checkbox"]:checked {
      width: 26px;
      height: 26px;
      background: url("~@/assets/img/icon-selected02.svg") no-repeat;
      background-size: 100%;
    }
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      margin-right: 8px;
      border: 1px solid #e6e6e6;
      border-radius: 100px;
      width: 26px;
      height: 26px;
    }
  }
}
</style>
