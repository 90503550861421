<template>
  <div class="alert">
    <div class="icon">
      <a-icon type="info-circle" />
    </div>
    <span>Some variants are updated,Consider updating this product</span>
    <a-button size="small" class="btns" @click="clickFn">See variants</a-button>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    clickFn() {
      this.$emit('alert')
    }
  }
}
</script>
<style scoped lang="scss">
.alert {
  padding: 8px 15px 8px 37px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  position: relative;
  display: flex;
  align-items: center;
  .icon {
    position: absolute;
    top: 10px;
    left: 16px;
  }
  button {
    margin-left: auto;
  }
}
</style>
