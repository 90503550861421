<template>
  <a-modal
    :title="false"
    :visible="visible"
    :footer="null"
    centered
    :width="computedWidth"
    :body-style="{
      padding:'1.5vw 0vw 1vw'
    }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="LookImgsContainer">
      <div class="content-view">
        <a-carousel
          effect="fade"
          :autoplay="false"
          arrows
          dots-class="slick-dots slick-thumb"
        >
          <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
          >
            <!-- <a-icon type="left-circle" /> -->
            <a-icon type="left" />
          </div>
          <div
            slot="nextArrow"
            class="custom-slick-arrow"
            style="right: 10px"
          >
            <!-- <a-icon type="right-circle" /> -->
            <a-icon type="right" />
          </div>

          <a slot="customPaging" slot-scope="props">
            <img class="imgIcon" :src="getImgUrl(props.i)">
          </a>

          <div v-for="(item, index) in bannerList" :key="`caroul${index}`">
            <img
              :style="{ height: `${computedHeight}vw` }"
              :src="item"
              class="bannerItem"
              fit="cover"
            >
          </div>
        </a-carousel>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  components: {},
  props: ['visible', 'height', 'bannerList'],
  data() {
    return {
      confirmLoading: false
    }
  },
  computed: {
    computedWidth() {
      return `${580 / 16}vw`
    },
    computedHeight() {
      return this.height / 16
    }
  },
  methods: {
    handleOk() {
      this.$emit('update:visible', false)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    getImgUrl(i) {
      return this.bannerList[i]
    }
  }
}
</script>

<style lang="less" scoped>
.LookImgsContainer {
  height: 550px;
  .content-view {
    padding-top: 20px;
  }
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.ff-ss {
  font-family: Segoe UI-Regular, Segoe UI;
}
.fw-400 {
  font-weight: 400;
}
.fc-333 {
  color: #333333;
}
.fc-999 {
  color: #999999;
}
.h-auto {
  height: auto !important;
}
.bannerItem {
  object-fit: cover;
  width: 100%;
  margin-right: 5px;
}
.imgIcon{
   object-fit: cover;
}
.ant-carousel /deep/ .slick-slide {
  text-align: center;
  height: 450px;
  line-height: 450px;
  // background: #364d79;
  overflow: hidden;
}

.ant-carousel /deep/ .custom-slick-arrow {
  width: 50px;
  height: 50px;
  font-size: 50px;
  color: #999999;
  // background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.6;
}
.ant-carousel /deep/ .custom-slick-arrow:before {
  display: none;
}
.ant-carousel /deep/ .custom-slick-arrow:hover {
  opacity: 1;
}

.ant-carousel /deep/ .slick-slide h3 {
  color: #fff;
}

.ant-carousel /deep/ .slick-dots {
  height: auto;
}
.ant-carousel /deep/ .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 75%;
}
.ant-carousel /deep/ .slick-thumb {
  bottom: -65px;
}
.ant-carousel /deep/ .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel /deep/ .slick-thumb li img {
  width: 100%;
  height: 100%;
  // filter: grayscale(100%);
}
.ant-carousel /deep/ .slick-thumb li.slick-active img {
  // filter: grayscale(0%);
}
</style>
