<template>
  <div>
    <div class="myProductsContainer pb40 ignore_view">
      <a-breadcrumb class="mt40 mb25">
        <a-breadcrumb-item>
          <span class="font16 color333 fontBold">Products</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div>
        <a-tabs v-model="accesskey" @change="tabCallback">
          <a-tab-pane key="1" tab="POD Products">
            <product-list
              ref="podProductList"
              :init-publish-product-id="initPodPublishProductId"
              :category="enums.blankProductTypeCategoryEnum.SIMPLE.value"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Diy Products">
            <product-list
              ref="diyProductList"
              :init-publish-product-id="initDiyPublishProductId"
              :category="enums.blankProductTypeCategoryEnum.DIY.value"
            />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

  </div>
</template>
<script>
import ProductList from './components/product_list'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'MyProducts',
  components: {
    ProductList
  },
  computed: {
    ...mapState({
      IsPushProduct: state => state.User.isPushProduct
    })
  },
  data() {
    return {
      accesskey: '1',
      enums: {
        blankProductTypeCategoryEnum
      },
      initPodPublishProductId: null,
      initDiyPublishProductId: null
    }
  },
  mounted() {
    console.log(this.$route.query.type)
    if (this.$route.query.type) {
      if (this.$route.query.type === '1') {
        this.accesskey = '1'
      } else {
        this.accesskey = '2'
      }
    }
    if (this.IsPushProduct) {
      this.$nextTick(() => {
        this.publishProduct(this.IsPushProduct)
      })
    }
  },
  methods: {
    ...mapMutations('User', [
      'IsPushProductmutations'
    ]),
    tabCallback(e) {
      console.log(e)
    },
    publishProduct(customProductId) {
      this.IsPushProductmutations(null)
      if (this.accesskey === '1') {
        this.initPodPublishProductId = customProductId
      } else {
        this.initDiyPublishProductId = customProductId
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/less/theme.less";
.ant-dropdown-open {
  .actionsBtnicon {
    transform: rotate(180deg);
  }
}

.myProductsContainer {
  width: @pageWidth;
  margin: 0 auto;

}
</style>
