<template>
  <a-modal :visible="viewRecordVisible" :footer="null" width="50vw" @cancel="$emit('handleCancel')">
    <div class="d-flex a-center j-center fontBold font18 mt10">Custom File</div>
    <div v-if="viewRecord">
      <template v-if="viewRecord.imageBoxInfoList && viewRecord.imageBoxInfoList.length > 0">
        <div v-for="(imageBox,index) in viewRecord.imageBoxInfoList" :key="imageBox.name+index">
          <div class="fontBold font16">{{ imageBox.name }}</div>
          <div class="d-flex flex-warp mt15">
            <img class="viewImg" :src="imageBox.imageUrl">
          </div>
        </div>
      </template>
      <template v-if="viewRecord.inputBoxInfoList && viewRecord.inputBoxInfoList.length > 0">
        <div v-for="(inputBox,index) in viewRecord.inputBoxInfoList" :key="inputBox.name+index">
          <div class="fontBold font16">
            {{ inputBox.name }}
          </div>
          <div class="mt10 mb10">{{ inputBox.text }}</div>
        </div>
      </template>

    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RecordViewModal',
  props: ['viewRecordVisible', 'viewRecord']
}

</script>
<style lang="less" scoped>
        .viewImg{
            width: 100px;
            height: 100px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
</style>
