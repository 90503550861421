<template>
  <div class="content bgwhite">
    <div class="d-flex a-center j-sb">
      <a-input-search v-model="page.keyword" placeholder="Search Product" style="width: 300px" @change="research" />

      <div class="d-flex a-center ">
        <a-select
          v-if="CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value"
          v-model="productStatus"
          class="selectcontainer"
          @change="onChangeStatus"
        >
          <a-select-option value="All">All · {{ productStatistics.allCount }}</a-select-option>
          <a-select-option value="Unpublished">Unpublished · {{ productStatistics.unPublishedCount }}</a-select-option>
          <a-select-option value="Published">Published · {{ productStatistics.publishedCount }}</a-select-option>
          <a-select-option value="Hidden in store">Hidden in store · {{ productStatistics.hiddenInStoreCount }}</a-select-option>
        </a-select>
        <a-button :disabled="!selectedProducts || selectedProducts.length === 0" :class="`copyBtn ${!(!selectedProducts || selectedProducts.length === 0)?'active':''} plr15 d-flex j-center userSelectNone a-center commontransition  cursorPointer`" @click="copyProduct">Copy Product</a-button>
        <a-button :disabled="!selectedProducts || selectedProducts.length === 0" :class="`delBtn ${!(!selectedProducts || selectedProducts.length === 0)?'active':''} plr15 d-flex j-center userSelectNone a-center commontransition  cursorPointer`" @click="delProduct">Delete</a-button>
      </div>
    </div>
    <div class="mt25">
      <a-table
        row-key="id"
        :pagination="false"
        :bordered="false"
        :columns="columns"
        :data-source="pageList"
        :loading="loading"
        :row-selection="{
          selectedRowKeys: selectedProducts,
          onChange: onSelectChange
        }"
      >
        <template slot="name" slot-scope="text, record">
          <div class="d-flex a-center">
            <img
              class="goodicon mr20 cursorPointer"
              :src="record.mainImageUrl"
              @click="showProductImages(record.imageList)"
            >
            <span
              :title="record.title"
              class="fontBold color333 textEllipsisOneLine primaryHover cursorPointer"
            >
              {{ record.title }}
            </span>
            <a-tag v-if="record.source === enums.customProductSourceEnum.MERCHANT_UPLOAD.value" style="margin-left: 5px" color="orange">
              Manual
            </a-tag>
          </div>
        </template>
        <div slot="customFile" slot-scope="text, record" class="d-flex tableProductView">
          <div v-if="record.imageBoxInfoList && record.imageBoxInfoList.length > 0" class="productImg " @click="showProductImages([record.imageBoxInfoList[0].imageUrl])">
            <img class="productImg" :src="record.imageBoxInfoList[0].imageUrl">
          </div>
          <div class="productMessageView d-flex flex-column">
            <div v-if="record.inputBoxInfoList && record.inputBoxInfoList.length > 0" class="fs-14 ff-ss primaryHover">
              {{ record.inputBoxInfoList[0].text }}
            </div>
            <div v-if="(record.imageBoxInfoList && record.imageBoxInfoList.length > 0) || (record.inputBoxInfoList && record.inputBoxInfoList.length > 0)" class="primaryHover fs-14 ff-ss cursorPointer colorprimary mt-auto userSelectNone fontBold" @click="selectViewRecord(record)">
              View More
            </div>
          </div>
        </div>
        <div
          slot="productNo"
          slot-scope="text, record"
          class="d-flex flex-column a-center colorgraylightColor "
        >

          <div class="variantstext">
            {{ record.productNo }}
          </div>
        </div>
        <div
          slot="variants"
          slot-scope="text, record"
          class="d-flex flex-column a-center colorgraylightColor "
        >
          <alerts
            v-if="record.isBlankProductSkuUpdate"
            @alert="showSkuUpdate(record)"
          />
          <div class="variantstext">
            <div v-if="record.option1Name" class="variantstext">
              {{ record.option1Name }}：{{ record.option1Num }}
            </div>
            <div v-if="record.option2Name" class="variantstext">
              {{ record.option2Name }}：{{ record.option2Num }}
            </div>
            <div v-if="record.option3Name" class="variantstext">
              {{ record.option3Name }}：{{ record.option3Num }}
            </div>
          </div>
        </div>
        <div
          slot="branding"
          slot-scope="text, record"
          class="d-flex flex-column a-center colorgraylightColor "
        >
          <div v-for="(item,index) in record.fittingsInfoList" :key="index" class="branding-img">
            <img v-if="item.imageUrl" :src="item.imageUrl" alt="">
          </div>
        </div>
        <div
          slot="status"
          slot-scope="text, record"
          class="d-flex a-center j-center"
        >
          <span v-if="publishingProductId === record.id">
            <a-spin :spinning="true">Publishing</a-spin>
          </span>
          <span
            v-else-if="record.isHiddenInStore"
            style="color:gray"
            class="userSelectNone statusText3"
          >
            Hidden in store
          </span>
          <span
            v-else-if="record.status === enums.customProductStatusEnum.PUBLISHED.value"
            class=" userSelectNone statusText2"
          >
            Published
          </span>
          <span
            v-else-if="record.status === enums.customProductStatusEnum.UNPUBLISHED.value"
            class="userSelectNone statusText color4CBBF9"
          >
            Unpublish
          </span>
        </div>
        <div slot="action" slot-scope="text, record" class="d-flex a-center">
          <div class="ml-auto cursorPointer userSelectNone">
            <a-dropdown>
              <div
                class="d-flex a-center actionsBtn plr10 j-sb colorgraylightColor"
              >
                Action
                <a-icon
                  type="down"
                  class="font16 mt5 actionsBtnicon commontransition"
                />
              </div>
              <a-menu
                slot="overlay"
                style="animation: menu-sub-dropdown-animation-fade-in, menu-sub-dropdown-animation-move-up !important;
              animation-duration: 0.3s, 0.3s !important;"
              >
                <a-menu-item>
                  <div class="actionsBtnItem" @click="toEditProduct(record)">
                    Edit
                  </div>
                </a-menu-item>
                <a-menu-item>
                  <div class="actionsBtnItem" @click="toOrderList(record)">
                    Create an order
                  </div>
                </a-menu-item>
                <a-menu-item
                  v-if="record.status ===enums.customProductStatusEnum.PUBLISHED.value &&!record.isHiddenInStore"
                  @click="hideShopify(record)"
                >
                  <div class="actionsBtnItem">Hide on {{ CurrentShop.shopType | dictFilter(enums.shopTypeEnum) }}</div>
                </a-menu-item>
                <a-menu-item
                  v-if="record.status ===enums.customProductStatusEnum.PUBLISHED.value &&record.isHiddenInStore"
                  @click="showShopify(record)"
                >
                  <div class="actionsBtnItem">Show on {{ CurrentShop.shopType | dictFilter(enums.shopTypeEnum) }}</div>
                </a-menu-item>
                <a-menu-item
                  v-if=" record.status ===enums.customProductStatusEnum.PUBLISHED.value &&
                    (CurrentShop.shopType ===enums.shopTypeEnum.SHOPIFY.value ||
                      CurrentShop.shopType ===enums.shopTypeEnum.WOOCOMMERCE.value ||
                      CurrentShop.shopType ===enums.shopTypeEnum.ETSY.value)"
                  @click="seeInStore(record)"
                >
                  <div class="actionsBtnItem">See in store</div>
                </a-menu-item>
                <a-menu-item
                  v-if="
                    (record.status === enums.customProductStatusEnum.UNPUBLISHED.value && CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value) ||
                      (record.status === enums.customProductStatusEnum.UNPUBLISHED.value && CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value) ||
                      (record.status === enums.customProductStatusEnum.UNPUBLISHED.value && CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)
                  "
                  :disabled="publishingProductId"
                  @click="pushProduct(record)"
                >
                  <a-spin :spinning="publishingProductId === record.id">
                    <div class="actionsBtnItem">Publish</div>
                  </a-spin>
                </a-menu-item>
                <a-menu-item
                  v-if="record.source === enums.customProductSourceEnum.MERCHANT_UPLOAD.value"
                  @click="toAgainUpload(record)"
                >
                  <div class="actionsBtnItem">Again upload</div>
                </a-menu-item>
                <a-menu-item @click="deleteProduct(record)">
                  <div class="actionsBtnItem">Delete</div></a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-table>
      <div class="d-flex a-center j-sb mt40 w-100">
        <a-select v-model="page.size" class="actionsSelect" @change="research">
          <a-select-option :value="10">10</a-select-option>
          <a-select-option :value="50">50</a-select-option>
          <a-select-option :value="100">100</a-select-option>
        </a-select>
        <a-pagination
          :current="page.current"
          :page-size="page.size"
          :total="pageTotal"
          show-quick-jumper
          @change="pageChange"
        />
      </div>
    </div>
    <!--预览图片组件-->
    <look-imgs-modal
      :banner-list="previewImages"
      height="470"
      :visible.sync="showPreviewImage"
    />
    <!--推送后引导到评论-->
    <a-modal :visible.sync="pushIntroduceDialog.show" icon="info-circle">
      <p v-html="pushIntroduceDialog.text" />
      <template slot="footer">
        <a-button type="primary" @click="pushIntroduceDialog.show = false">ok</a-button>
        <a-button class="bord" @click="goToWriteReview">
          Write a review
        </a-button>
      </template>
    </a-modal>
    <!--sku变更后设置组件-->
    <skuUpdate
      v-if="skuUpdateDialog.show"
      ref="skuUpdate"
      :product-id="skuUpdateDialog.product.blankProductId"
      :cus-id="skuUpdateDialog.product.id"
      @close="skuUpdateDialog.show = false"
      @setSkuPrice="doSkuUpdate"
    />
    <priceUpdate
      v-if="priceUpdateDialog.show"
      ref="priceUpdate"
      :cus-id="priceUpdateDialog.product.id"
      :status="priceUpdateDialog.product.status"
      :sku-price="priceUpdateDialog.skuPrice"
      @close="onPriceDialogClose"
      @shouldPush="onPriceDialogShouldPush"
    />
    <record-view-modal :view-record-visible="viewRecordVisible" :view-record="viewRecord" @handleCancel="viewRecordVisible=false" />
  </div>
</template>

<script>
import {
  batchDelProduct,
  hideOnShopify,
  productCount,
  productPage,
  publish,
  publishStatus,
  showOnShopify,
  productRemoveBatch
} from '@/api/product'
import RecordViewModal from './RecordViewModal'
import shopTypeEnum from '@/enums/shopTypeEnum'
import { mapState } from 'vuex'
import util from '@/utils/util'
import alerts from '@/components/alerts'
import priceUpdate from '@/components/priceUpdate.vue'
import skuUpdate from '@/components/skuUpdate.vue'
import LookImgsModal from './look_imgs_modal'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum'
import customProductStatusEnum from '@/enums/custom_product/customProductStatusEnum'
import customProductSourceEnum from '@/enums/custom_product/customProductSourceEnum'

export default {
  name: 'ProductList',
  components: {
    LookImgsModal,
    alerts,
    priceUpdate,
    skuUpdate,
    RecordViewModal
  },
  props: {
    category: {
      type: Number,
      default: blankProductTypeCategoryEnum.SIMPLE.value
    },
    initPublishProductId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      choiceProduct: '',
      isyindao: false,
      yindao: '',
      loading: true,
      pageList: [],
      productStatistics: {}, // 产品统计
      pageTotal: 1,
      productStatus: 'ALL',
      page: {
        current: 1,
        size: 10,
        keyword: '', // 关键字
        status: null,
        isHiddenInStore: null
      },
      skuUpdateIsShow: false,
      priceUpdateIsShow: false,
      skuPrice: [],
      showPreviewImage: false,
      previewImages: [],
      viewRecord: undefined, // 当前预览的商品
      viewRecordVisible: false, // 当前预览的商品弹框显示
      enums: {
        customProductStatusEnum,
        customProductSourceEnum,
        shopTypeEnum
      },
      selectedProducts: [],
      publishingProductId: null,
      pushIntroduceDialog: {
        show: false,
        text: ''
      },
      skuUpdateDialog: {
        show: false,
        product: null
      },
      priceUpdateDialog: {
        show: false,
        product: null,
        skuPrice: null
      }
    }
  },
  computed: {
    ...mapState({
      IsPullProduct: state => state.User.isPullProduct,
      IsPushProduct: state => state.User.isPushProduct,
      CurrentShop: state => state.User.merchantInfo.currentShop
    }),
    columns() {
      var title = {
        dataIndex: 'title',
        title: 'Product',
        key: 'title',
        width: '25%',
        scopedSlots: { customRender: 'name' }
      }
      var productNo = {
        dataIndex: 'productNo',
        align: 'center',
        title: 'Sku',
        key: 'productNo',
        scopedSlots: { customRender: 'productNo' }
      }
      var variants = {
        dataIndex: 'variants',
        align: 'center',
        title: 'Variants',
        key: 'color',
        scopedSlots: { customRender: 'variants' }
      }
      var branding = {
        dataIndex: 'branding',
        align: 'center',
        title: 'Branding',
        key: 'branding',
        scopedSlots: { customRender: 'branding' }
      }
      var status = {
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        title: 'Status',
        width: '10%',
        scopedSlots: { customRender: 'status' }
      }
      var action = {
        title: 'Action',
        align: 'right',
        key: 'action',
        width: '10%',
        scopedSlots: { customRender: 'action' }
      }
      var customFile = {
        title: 'Custom File',
        key: 'customFile',
        width: '20%',
        scopedSlots: { customRender: 'customFile' }
      }
      if (this.CurrentShop.shopType === shopTypeEnum.SHOPIFY.value || this.CurrentShop.shopType === shopTypeEnum.WOOCOMMERCE.value || this.CurrentShop.shopType === shopTypeEnum.ETSY.value) {
        if (this.category === blankProductTypeCategoryEnum.DIY.value) {
          return [title, productNo, customFile, variants, branding, status, action]
          // return [title, productNo, customFile, variants, status, action]
        } else {
          return [title, productNo, variants, status, action]
        }
      } else {
        if (this.category === blankProductTypeCategoryEnum.DIY.value) {
          // return [title, productNo, customFile, variants, action]
          return [title, productNo, customFile, variants, branding, action]
        } else {
          return [title, productNo, variants, branding, action]
          // return [title, productNo, variants, action]
        }
      }
    }
  },
  async mounted() {
    await this.search()
    if (this.initPublishProductId) {
      this.pushProduct({
        id: this.initPublishProductId
      })
    }
  },
  methods: {
    // 当前预览的商品
    selectViewRecord(record) {
      this.viewRecord = record
      this.viewRecordVisible = true
    },
    onChangeStatus() {
      if (this.productStatus === 'All') {
        this.page.status = null
        this.page.isHiddenInStore = null
      }
      if (this.productStatus === 'Unpublished') {
        this.page.status = customProductStatusEnum.UNPUBLISHED.value
        this.page.isHiddenInStore = null
      }
      if (this.productStatus === 'Published') {
        this.page.status = customProductStatusEnum.PUBLISHED.value
        this.page.isHiddenInStore = false
      }
      if (this.productStatus === 'Hidden in store') {
        this.page.status = null
        this.page.isHiddenInStore = true
      }
      this.research()
    },
    research() {
      this.page.current = 1
      this.search()
    },
    pageChange(current) {
      this.page.current = current
      this.search()
    },
    async search() {
      this.loading = true
      this.selectedProducts = []
      const { data } = await productPage({
        current: this.page.current,
        size: this.page.size,
        keyword: this.page.keyword,
        status: this.page.status,
        blankProductTypeCategory: this.category,
        isHiddenInStore: this.page.isHiddenInStore
      })
      this.pageTotal = data.total
      this.pageList = data.records || []
      this.getProductStatistics()
      this.loading = false
    },
    async getProductStatistics() {
      const { data } = await productCount({ blankProductTypeCategory: this.category })
      this.productStatistics = data
    },
    showProductImages(record) {
      this.previewImages = record
      this.showPreviewImage = true
    },
    onSelectChange(selectedRowKeys) {
      this.selectedProducts = selectedRowKeys
    },
    toEditProduct(record) {
      this.callNewPage({
        path: '/mockups',
        query: {
          customProductId: record.id,
          productId: record.blankProductId
        }
      })
    },
    toOrderList() {
      this.callNewPage({
        path: '/orderlist',
        query: { isOrder: true, isSamples: false }
      })
    },
    async hideShopify(record) {
      const _this = this
      const { code, msg } = await hideOnShopify({
        customProductId: record.id
      })
      if (code !== 200) {
        this.$message.error(msg)
      } else {
        _this.search()
      }
    },
    async showShopify(record) {
      const _this = this
      const { code, msg } = await showOnShopify({
        customProductId: record.id
      })
      if (code !== 200) {
        this.$message.error(msg)
      } else {
        _this.search()
      }
    },
    seeInStore(record) {
      if (record.productStoreUrl) {
        window.open(record.productStoreUrl)
      }
    },
    deleteProduct(record) {
      const _this = this
      this.$confirm({
        title: 'Delete?',
        content: 'Are you sure you want to delete these products?',
        okText: 'Delete',
        cancelText: 'cancel',
        onOk: async function() {
          await batchDelProduct({
            customProductIdList: [record.id]
          })
          _this.research()
        }
      })
    },
    copyProduct() {
      if (!this.selectedProducts || this.selectedProducts.length === 0) {
        this.$message.error('Please select product')
        return
      }

      const _this = this
      this.$confirm({
        title: `Copy ${_this.selectedProducts.length} products to …`,
        content:
            'Due to sales channel limitations some sales channel specific product details like product description text formating and tags might be lost in copy process',
        async onOk() {
          _this.callNewPage({
            path: '/copyOnceProduct',
            query: {
              customProductId: JSON.stringify(_this.selectedProducts),
              shopId: _this.CurrentShop.shopId
            }
          })
        }
      })
    },
    delProduct() {
      if (!this.selectedProducts || this.selectedProducts.length === 0) {
        this.$message.error('Please select product')
        return
      }
      const _this = this
      this.$confirm({
        title: `Batch deletion`,
        content:
            'Are you sure to delete these products?',
        async onOk() {
          const { code } = await productRemoveBatch({
            customproductIdlist: _this.selectedProducts
          })
          if (code === 200) {
            _this.$message.success('Delete successful')
            _this.search()
          }
        }
      })
    },
    async pushProduct(record) {
      this.publishingProductId = record.id

      const { code, data, msg } = await publish({ customProductId: record.id })
      try {
        if (code !== 200) {
          // 0不用重新加载分页  1重新加载分页
          this.$message.error(msg)
          return
        }
        await this.loopPushProductStatus(data.customProductPublishId)
      } finally {
        this.publishingProductId = null
      }
    },
    async loopPushProductStatus(id) {
      var _this = this
      const { data } = await publishStatus({
        customProductPublishId: id
      })
      if (data.status === 2) {
        // 是否显示推送引导弹窗
        if (data.introduceText) {
          _this.pushIntroduceDialog.show = true
          _this.pushIntroduceDialog.text = data.introduceText
        }
        // 发布成功
        _this.search()
        return
      } else if (data.status === 3) {
        // 发布失败
        _this.$message.error(data.failReason || 'push failed')
        return
      }
      await util.waitForMs(1000)
      await this.loopPushProductStatus(id)
    },
    goToWriteReview() {
      window.open('https://apps.shopify.com/jetprint-fulfillment/reviews')
    },
    showSkuUpdate(record) {
      this.skuUpdateDialog.product = record
      this.skuUpdateDialog.show = true
    },
    doSkuUpdate(val) {
      this.priceUpdateDialog.product = this.skuUpdateDialog.product
      this.priceUpdateDialog.skuPrice = val
      this.priceUpdateDialog.show = true
    },
    onPriceDialogClose() {
      this.priceUpdateDialog.show = false
      this.skuUpdateDialog.show = false
      this.search()
    },
    onPriceDialogShouldPush() {
      this.priceUpdateDialog.show = false
      this.skuUpdateDialog.show = false
      this.pushProduct(this.priceUpdateDialog.product)
    },
    toUploadProduct() {
      this.callNewPage(`/upload_product`)
    },
    toAgainUpload(record) {
      this.callNewPage(`/upload_product_edit/${record.id}`)
    }
  }
}
</script>

<style lang="less" scoped>
@import "@/assets/less/theme.less";
.tableProductView{
    .productImg{
      width: 77px;
      height: 77px;
      border-radius: 8px;
    }
    .productMessageView{
      padding-left: 14px;
      .smallImg{
        width: 30px;
        height: 30px;
        border-radius: 4px;
      }
    }
  }
.actionsBtnItem {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
}
.variantstext{
  color: #7E8299;
  font-size: 13.975px !important;
  font-weight: 600 !important;
}
.branding-img{
  img{
    width: 28px;
    height: 28px;
    border-radius: 2px 2px 2px 2px;
    margin-right: 12px;
  }
}
.statusText{
    padding: 7px 14px;
    border-radius: 8px;
    background-color: #EEF9FF;
  }
  .statusText2{
    padding: 7px 14px;
    border-radius: 8px;
    background-color: #E8FFF3;
    color: #66CF90;
  }
  .statusText3{
    padding: 7px 14px;
    border-radius: 8px;
    background-color: #FFF5F8;
    color: #F36B84;
  }
    /deep/  .ant-dropdown-menu-item-active{
      color: #4cbbf9;
      background: #fff;
      .actionsBtnItem{
        background: #f5f8fa !important;
        border-radius: 12px;
      }
    }
.content {
  border-radius: 12px;
  padding: 24px;
  // :global{

  // }
  .actionsBtn {
    width: 140px;
    height: 39px;
    border-radius: 8px;
    background: #f5f8fa;
  }

  .color4CBBF9 {
    color: #4cbbf9;
  }
  .selectcontainer {
    width: 203px;
    margin-right: 24px;
  }
  .goodicon {
    height: 60px;
    width: 60px;
    border-radius: 8px;
  }
  .actionsSelect {
    width: 140px;
  }
  .copyBtn {
    height: 39px;
    background-color: #f5f8fa;
    border-radius: 8px;
    &.active{
      background: #009ef7 !important;
      color: #fff !important;
    }
    &:hover {
      color: @whiteColor !important;
      background-color: #4cbbf9 !important;
    }
  }
  .delBtn {
    height: 39px;
    background-color: #fff5f8;
    color:#f2416c !important;
    border-radius: 8px;
    margin-left: 10px;
    &.active{
      background: #fff5f8 !important;
      color: #f2416c !important;
    }
    &:hover {
      color: @whiteColor !important;
      background-color: #fbf4f6 !important;
      color: #f2416c !important;
    }
  }
}
/deep/ .ant-table-thead > tr > th {
  background-color: transparent;
  border-bottom: 1px solid #eeeeee;
}
/deep/ .ant-table-tbody > tr > td {
  background-color: transparent !important;
  border-bottom: 1px solid #eeeeee !important;
  vertical-align: middle;
}
/deep/ .ant-table-row >  {
  border-bottom: none;
}

.inputcontainer {
  display: inline-block;
  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .inputclass {
    border-radius: 8px !important;
    background-color: #f5f8fa !important;
    border: none !important;
    padding-left: 35px;
  }
}
</style>
