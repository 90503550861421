<template>
  <div>
    <!-- ok-text="Update"
      cancel-text="Save"
      @ok="handleOk"
      @cancel="handCel" -->
    <a-modal
      v-model="modeShow"
      title="Update Variants"
      :footer="null"
      width="900px"
      @cancel="maskClose"
    >
      <div class="titles">
        please confirm the Retail Price again
      </div>
      <div class="variants">
        <div class="right">
          <div class="title">Pricing</div>
          <div class="table">
            <div class="thead clearfix">
              <div class="tr">Pizes</div>
              <div class="tr">Price, $</div>
              <div class="tr">Retail price, $</div>
              <div class="tr">Compare at price</div>
              <div class="tr">Profit, $</div>
              <div class="tr">Profit, %</div>
            </div>
            <div class="tbody">
              <div
                v-for="(item, index) in variant"
                :key="index"
                class="tfirst"
                :class="{ tbup: item.isUp == true }"
              >
                <div
                  class="tr clearfix"
                  :class="{ tbActive: item.isUp == false }"
                  @click.stop="downUp(index)"
                >
                  <div class="td firstSku" style="cursor: pointer;">
                    <a-icon v-if="item.sku2.length > 0" type="caret-down" />
                    {{ item.name }}
                  </div>
                  <div class="td">{{ item.costs }}</div>
                  <div class="td" @click.stop="stopClick">
                    <a-input-number
                      v-model="item.retailPrice"
                      :min="1"
                      :max="1000000"
                      @change="changeRetail(index, $event)"
                    />
                  </div>
                  <div class="td" @click.stop="stopClick">
                    <a-input-number
                      v-model="item.compareAtPrice"
                      :min="1"
                      :max="1000000"
                      @change="changeCompare(index, $event)"
                    />
                  </div>
                  <div class="td">{{ item.profit | MoneyFormat }}</div>
                  <div class="td">{{ item.profit2 | RateFormat }}%</div>
                </div>
                <div class="node-title">{{ item.skuName }}</div>
                <div
                  v-for="(item2, index2) in item.sku2"
                  :key="index2"
                  class="tfirst tbActive two"
                >
                  <div class="tr tbActive clearfix">
                    <div class="td" @click="downUp2(index, index2)">
                      <a-icon
                        v-if="item2.skuthree.length > 0"
                        type="caret-down"
                      />
                      {{ item2.name }}
                    </div>
                    <div class="td">{{ item2.costs }}</div>
                    <div class="td" @click.stop="stopClick">
                      <a-input-number
                        v-model="item2.retailPrice"
                        :min="1"
                        :max="1000000"
                        @change="changeRetail2(index, index2, $event)"
                      />
                    </div>
                    <div class="td" @click.stop="stopClick">
                      <a-input-number
                        v-model="item2.compareAtPrice"
                        :min="1"
                        :max="1000000"
                        @change="changeCompare2(index, index2, $event)"
                      />
                    </div>
                    <div class="td">{{ item2.profit | MoneyFormat }}</div>
                    <div class="td">{{ item2.profit2 | RateFormat }}%</div>
                  </div>
                  <div v-show="item2.isUp == true" class="node-title pl20">
                    {{ item2.skuName }}
                  </div>
                  <div
                    v-for="(item3, index3) in item2.skuthree"
                    v-show="item2.isUp == true"
                    :key="index3 + 's'"
                    class="tfirst tbActive two mar0"
                  >
                    <div class="tr tbActive clearfix">
                      <div class="td pl20">{{ item3.name }}</div>
                      <div class="td">{{ item3.costs }}</div>
                      <div class="td">
                        <a-input-number
                          v-model="item3.retailPrice"
                          :min="1"
                          :max="1000000"
                          @change="changeRetail3(index, index2, index3, $event)"
                        />
                      </div>
                      <div class="td">
                        <a-input-number
                          v-model="item3.compareAtPrice"
                          :min="1"
                          :max="1000000"
                          @change="
                            changeCompare3(index, index2, index3, $event)
                          "
                        />
                      </div>
                      <div class="td">
                        {{ item3.profit | MoneyFormat }}
                      </div>
                      <div class="td">{{ item3.profit2 | RateFormat }}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <div class="btn">
          <a-button :loading="cancelLoading" @click="cancel">Cancel</a-button>
          <a-button
            type="primary"
            :loading="updateLoading"
            @click="update"
          >Update</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { skuModify, publishStatus } from '@/api/product'
export default {
  props: {
    cusId: {
      type: [Number, String]
    },
    status: {
      type: [Number, String]
    },
    skuPrice: {
      type: Array
    }
  },
  data() {
    return {
      variant: this.skuPrice,
      modeShow: true,
      cancelLoading: false,
      updateLoading: false
    }
  },
  mounted() {
    // this.getProductInfo();
  },
  methods: {
    // variant点击展开
    downUp(index) {
      if (this.variant[index].isUp === false) {
        this.variant[index].isUp = true
      } else {
        this.variant[index].isUp = false
      }
    },
    downUp2(index, index2) {
      if (this.variant[index]['sku2'][index2]['isUp'] === false) {
        this.variant[index]['sku2'][index2]['isUp'] = true
      } else {
        this.variant[index]['sku2'][index2]['isUp'] = false
      }
    },
    changeRetail(index, val) {
      this.variant[index]['retailPrice'] = val // 零售价
      this.variant[index]['profit'] =
        this.variant[index]['retailPrice'] - this.variant[index]['costs'] // profit利润
      const profit2 =
        this.variant[index]['profit'] / this.variant[index]['retailPrice']
      this.variant[index]['profit2'] = profit2.toFixed(1)

      for (let j = 0; j < this.variant[index]['sku2'].length; j++) {
        this.variant[index]['sku2'][j].retailPrice = this.variant[
          index
        ].retailPrice
        this.variant[index]['sku2'][j]['profit'] = this.variant[index][
          'profit'
        ]
        this.variant[index]['sku2'][j]['profit2'] = this.variant[index][
          'profit2'
        ]
        for (
          let i = 0;
          i < this.variant[index]['sku2'][j]['skuthree'].length;
          i++
        ) {
          this.variant[index]['sku2'][j]['skuthree'][i][
            'retailPrice'
          ] = this.variant[index].retailPrice
          this.variant[index]['sku2'][j]['skuthree'][i][
            'profit'
          ] = this.variant[index]['profit']
          this.variant[index]['sku2'][j]['skuthree'][i][
            'profit2'
          ] = this.variant[index]['profit2']
        }
      }
    },
    // 第二层的Retail
    changeRetail2(index, index2, val) {
      this.variant[index]['sku2'][index2]['retailPrice'] = val // 零售价
      this.variant[index]['sku2'][index2]['profit'] =
        this.variant[index]['sku2'][index2]['retailPrice'] -
        this.variant[index]['sku2'][index2]['costs'] // profit利润
      const profit2 = this.variant[index]['sku2'][index2]['profit'] / val
      this.variant[index]['sku2'][index2]['profit2'] = profit2.toFixed(1)
      for (
        let i = 0;
        i < this.variant[index]['sku2'][index2]['skuthree'].length;
        i++
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'retailPrice'
        ] = this.variant[index]['sku2'][index2].retailPrice
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit'
        ] = this.variant[index]['sku2'][index2]['profit']
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit2'
        ] = this.variant[index]['sku2'][index2]['profit2']
      }
    },
    // 第三层的Retail
    changeRetail3(index, index2, index3, val) {
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'retailPrice'
      ] = val // 零售价
      this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice'] -
        this.variant[index]['sku2'][index2]['skuthree'][index3]['costs'] // profit利润
      const profit2 =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] / val
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'profit2'
      ] = profit2.toFixed(1)
    },
    changeCompare(index, val) {
      if (
        this.variant[index]['compareAtPrice'] <
        this.variant[index]['retailPrice']
      ) {
        this.variant[index]['compareAtPrice'] =
          this.variant[index]['compareAtPrice'] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['compareAtPrice'] = val
      this.variant[index]['profit2'] =
        this.variant[index]['profit'] / this.variant[index]['retailPrice']
      for (let j = 0; j < this.variant[index]['sku2'].length; j++) {
        this.variant[index]['sku2'][j]['compareAtPrice'] = val
        this.variant[index]['sku2'][j]['profit2'] =
          this.variant[index]['sku2'][j]['profit'] /
          this.variant[index]['sku2'][j]['retailPrice']
        for (
          let i = 0;
          i < this.variant[index]['sku2'][j]['skuthree'].length;
          i++
        ) {
          this.variant[index]['sku2'][j]['skuthree'][i]['compareAtPrice'] = val
          this.variant[index]['sku2'][j]['skuthree'][i]['profit2'] =
            this.variant[index]['sku2'][j]['skuthree'][i]['profit'] /
            this.variant[index]['sku2'][j]['skuthree'][i]['retailPrice']
        }
      }
    },
    changeCompare2(index, index2, val) {
      if (
        this.variant[index]['sku2'][index2]['compareAtPrice'] <
        this.variant[index]['sku2'][index2]['retailPrice']
      ) {
        this.variant[index]['sku2'][index2]['compareAtPrice'] =
          this.variant[index]['sku2'][index2]['compareAtPrice'] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['sku2'][index2]['compareAtPrice'] = val
      this.variant[index]['sku2'][index2]['profit2'] =
        this.variant[index]['sku2'][index2]['profit'] /
        this.variant[index]['sku2'][index2]['retailPrice']
      for (
        let i = 0;
        i < this.variant[index]['sku2'][index2]['skuthree'].length;
        i++
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'compareAtPrice'
        ] = this.variant[index]['sku2'][index2].compareAtPrice
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit'
        ] = this.variant[index]['sku2'][index2]['profit']
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit2'
        ] = this.variant[index]['sku2'][index2]['profit2']
      }
    },
    changeCompare3(index, index2, index3, val) {
      if (
        this.variant[index]['sku2'][index2]['skuthree'][index3][
          'compareAtPrice'
        ] <
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice']
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][index3][
          'compareAtPrice'
        ] =
          this.variant[index]['sku2'][index2]['skuthree'][index3][
            'compareAtPrice'
          ] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'compareAtPrice'
      ] = val

      this.variant[index]['sku2'][index2]['skuthree'][index3]['profit2'] =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] /
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice']
    },
    async update() {
      this.updateLoading = true
      console.log(this.variant)
      const param = {}
      param.customProductId = this.cusId
      param.skuList = []
      const variantList = []
      for (let i = 0; i < this.variant.length; i++) {
        if (this.variant[i].id) {
          const param = {}
          param.compareAtPrice = this.variant[i].compareAtPrice
          param.id = this.variant[i].id
          param.retailPrice = this.variant[i].retailPrice
          variantList.push(param)
        } else if (this.variant[i].sku2[0].id) {
          for (let j = 0; j < this.variant[i].sku2.length; j++) {
            const param = {}
            param.compareAtPrice = this.variant[i].sku2[j].compareAtPrice
            param.id = this.variant[i].sku2[j].id
            param.retailPrice = this.variant[i].sku2[j].retailPrice
            variantList.push(param)
          }
        } else {
          for (let k = 0; k < this.variant[i].sku2.length; k++) {
            for (let l = 0; l < this.variant[i].sku2[k].skuthree.length; l++) {
              const param = {}
              param.compareAtPrice = this.variant[i].sku2[k].skuthree[
                l
              ].compareAtPrice
              param.id = this.variant[i].sku2[k].skuthree[l].id
              param.retailPrice = this.variant[i].sku2[k].skuthree[
                l
              ].retailPrice
              variantList.push(param)
            }
          }
        }
      }
      param.skuList = variantList
      console.log('variantList...', param)
      console.log('this.status...', this.status)
      try {
        const { code, msg } = await skuModify(param)
        if (code === 200) {
          if (this.status === 3 || this.status === 2) {
            this.$emit('shouldPush')
          } else {
            this.$emit('close')
            this.$message.success('Success')
          }
        } else {
          this.$message.error(msg)
        }
      } finally {
        this.updateLoading = false
      }
    },
    async pushStatu(id) {
      const _this = this
      for (;;) {
        const { data } = await publishStatus({
          customProductPublishId: id
        })
        if (data.status === 2) {
          _this.updateLoading = false
          _this.$message.success('Success')
          _this.$emit('close', 1)
          break
        } else if (data.status === 3) {
          // 发布失败
          _this.updateLoading = false
          _this.$message.error('pull failed')
          break
        }
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      }
    },
    cancel() {
      this.$emit('close', 0)
    },
    stopClick() {},
    maskClose() {
      this.$emit('close', 1)
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/mockup.scss";
.btns {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  .btn {
    margin-left: auto;
    button {
      margin-left: 15px;
    }
  }
}
</style>
